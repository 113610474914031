<template>
  <v-dialog v-model="dialog" max-width="600px" @click:outside="resetForm()">
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Accommodation</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <v-form
          @submit.prevent="saveForm"
          method="post"
          id="accommodation-form"
        >
          <v-text-field
            label="Name"
            v-model="fields.name"
            ref="accommodationName"
            type="text"
            outlined
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>
          <v-textarea
            label="Description"
            v-model="fields.description"
            outlined
            rows="3"
            :error="errors.hasOwnProperty('description')"
            :error-messages="errors['description']"
          ></v-textarea>
          <v-text-field
            label="Website Address"
            v-model="fields.website"
            type="url"
            outlined
            :error="errors.hasOwnProperty('website')"
            :error-messages="errors['website']"
          ></v-text-field>
          <v-textarea
            label="Postal Address"
            v-model="fields.address"
            outlined
            rows="3"
            :error="errors.hasOwnProperty('address')"
            :error-messages="errors['address']"
          ></v-textarea>
          <v-text-field
            label="Phone Number"
            v-model="fields.phone"
            type="tel"
            outlined
            :error="errors.hasOwnProperty('phone')"
            :error-messages="errors['phone']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="accommodation-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      accommodation: {},
      fields: {
        name: "",
        description: "",
        website: "",
        address: "",
        phone: "",
      },
      errors: {},
    };
  },

  watch: {
    dialog() {
      if (this.dialog) {
        this.$nextTick().then(() => {
          this.$refs.accommodationName.focus();
        });
      }
    },
  },

  methods: {
    openForm: function (accommodation = null) {
      if (accommodation !== null) {
        this.isEditing = true;
        this.fields.name = accommodation.name;
        this.fields.description = accommodation.description;
        this.fields.website = accommodation.website;
        this.fields.address = accommodation.address;
        this.fields.phone = accommodation.phone;
        this.accommodation = accommodation;
      }

      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields.name = "";
      this.fields.description = "";
      this.fields.website = "";
      this.fields.address = "";
      this.fields.phone = "";
      this.accommodation = {};
    },

    saveForm: function () {
      const appId = this.$route.params.id;
      this.loading = true;
      this.errors = {};

      let payload = {
        appId,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.accommodationId = this.accommodation.id;
      }

      this.$store
        .dispatch("drum/admin/saveAccommodation", {
          ...payload,
        })
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>